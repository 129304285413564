import './App.css';

import React, { useRef, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import beep from './assets/beep'

import axios from 'axios';

const client = axios.create({
    baseURL: process.env.REACT_APP_SERVER_CONNECTION,
    withCredentials: true
});

function App() {
  const pathname = window.location.pathname
  const scan = useRef('');
  const [response, setResponse] = useState({color: "rgba(255, 255, 255, 0)", text: "scan to start"});
  const validate = (secret) => {
    client.post('/validate/ticket', {
      secret,
      alias: pathname.slice(1)
    })
    .then(res => {
      beep(1600, 200);
      setResponse({
        color: "rgba(0, 255, 255, 120)",
        text: "validation success"
      })
    })
    .catch(err => {
      setResponse({
        color: "rgba(255, 0, 0, 120)",
        text: err.response.data.errorMessage
      })
    })
  }

  return (
    <>
      <QrReader
        constraints={{ 
          facingMode: 'environment',
          // width: 500,
          // height: 500,
          aspectRatio: 1,
        }}
        onResult={(result, error) => {
          if (!!result && result?.text != scan.current) {
            scan.current = result?.text
            validate(result?.text);
          }

          if (!!error) { }
        }}
        style={{ width: '100%' }}
      />
      <div style={{backgroundColor: response.color}}> {response.text} </div>
      <div> {scan?.current} </div>
    </>
  );
}

export default App;
